<template>
    <div class="assess-target-grid">
        <AssessHeader :currentStep="2" :dataSource="assessHeaderData"></AssessHeader>
        <sn-table-group class="table" :tableGroupAttributes="tableGroupAttributes" @handleChange="handleChange" :commonMethods="commonMethods"></sn-table-group>
    </div>
</template>
<script>
import { commonData } from '../sn-table-group-common';
import { selfData } from './model/2_sn-table-group';
import commonMethodMixin from '../commonMethodMixin';
import { mapActions } from 'vuex';
import { commonMethods } from './model/1_table-methods';
import AssessHeader from './components/assess_header'
export default {
    name: 'assess_record_grid',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            commonMethods,
            selfData,
            infoPage: 'assess_record_form',
            // 筛选项
            keyword: '', // 关键字
            assessHeaderData: {
                title: '评估记录',
                content: '评估记录是确定评估对象，描述评估内容与目的，约定实施评估开始、结束时间，而形成的数据记录。',
            },
            exportCode: '2002',
            exportName: "评估记录",
        };
    },
    components:{
        AssessHeader
    },
    mounted() {
    },
    methods: {
        ...mapActions([
            'GetPgjlInfoList', //获取评估记录信息列表
            'DeletePgjlInfo', //删除评估记录信息
        ]),
        async getTableData() {
            this.tableGroupAttributes.loading = true;
            let res = await this.GetPgjlInfoList({
                Key: this.keyword,	//String 内存放评估对象名称
                pageIndex: this.tableGroupAttributes.snPage.currentPage,	//Int 页数
                pageSize: this.tableGroupAttributes.snPage.tiaoshu,	//Int 页面数据量
            })
            this.tableGroupAttributes.snTable.table.data = res.RESULT || [];
            this.tableGroupAttributes.snPage.count = Number(res.COUNT) || 0;
            this.tableGroupAttributes.loading = false;
        },
        // 删除数据
        // eslint-disable-next-line no-unused-vars
        async delData(id, done, instance) {
            let res = await this.DeletePgjlInfo({
                Id: id
            });
            this.delOver(res, done);
        },
        delOver(res, done) {
            if (res.ISSUCCESS) {
                done();
                this.$message({
                    message: res.RESULTDESCRIPTION,
                    type: 'success'
                });
                // this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.$message({
                    message: res.RESULTDESCRIPTION,
                    type: 'error'
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.assess-target-grid{
    width: 100%;
    height: 100%;
    .table{
        width: 100%;
        height:calc(100% - 150px);
    }
}
</style>