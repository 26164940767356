const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "评估名称",
                prop: "PGJLMC",
            }, {
                label: "评估对象",
                prop: "PGDXMC",
            }, {
                label: "评估说明",
                prop: "PGJLSM",
            }, {
                label: "评估开始时间",
                prop: "PGQSSJ",
                substr: true,
                width: 150,
            }, {
                label: "评估结束时间",
                prop: "PGJSSJ",
                substr: true,
                width: 150,
            }],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnRead',
                    title: '查看',
                    icon: 'el-icon-view',
                    color: 'green',
                    showMethod: 'showReadBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnEdit',
                    title: '修改',
                    icon: 'el-icon-edit',
                    color: 'green',
                    showMethod: 'showEditBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'el-icon-delete',
                    color: 'red',
                    showMethod: 'showDelBtn',
                }],
                width: 150,
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        // selectData: [{
        //     label: '对象类型',
        //     clearable: true,
        //     placeholder: "请选择对象类型",
        //     list: [],
        //     optionLabel: 'Name',
        //     optionValue: 'Code',
        //     value: 'PGDXLX',
        //     operateType: 'search',
        //     isShow: true
        // }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };